import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Tooltip,
} from '@material-ui/core';
import { nanoid } from '@reduxjs/toolkit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useFormContext } from 'react-hook-form';
import { useField } from 'hooks/useField.hook';
import { generateTheme } from '../../../utils/helpers';
import { MEDICAL_CONDITIONS } from './medical-conditions-table.utils';

const useStyles = makeStyles(theme => ({
  head: {
    backgroundColor: '#293241',
  },
  cellHeader: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    color: 'white',
  },
  row: {
    '&:nth-child(odd)': {
      backgroundColor: '#e3e3e3',
    },
    '&:nth-child(even)': {
      backgroundColor: '#a9a9a9',
    },
  },
}));

export const MedicalConditionsTable = () => {
  const [isFormOpen, toggleForm] = useState(false);

  const nameField = useField('');

  const noteField = useField('');

  const form = useFormContext();

  const classes = useStyles();

  const medicalConditions = form.watch('medicalConditions');

  const onDeleteMedicalConditionHandler = item => {
    const _medicalConditions = medicalConditions.filter(
      medicalCondition => medicalCondition._id !== item._id,
    );
    form.setValue('medicalConditions', _medicalConditions);
  };

  const onOpenFormHandler = () => {
    toggleForm(prevState => !prevState);
  };

  const onSubmitHandler = () => {
    const name = nameField.value;
    const note = noteField.value;

    const nameValidation = nameField.validate();
    const noteValidation = noteField.validate();

    if (nameValidation || noteValidation) {
      const payload = {
        name,
        note,
        _id: nanoid(),
      };
      const _medicalConditions = [...medicalConditions, payload];
      form.setValue('medicalConditions', _medicalConditions);
      toggleForm(false);
      nameField.reset();
      noteField.reset();
    }
  };

  return (
    <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
      <Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell align="center" className={classes.cellHeader}>
                  Condición
                </TableCell>
                <TableCell align="center" className={classes.cellHeader}>
                  Nota
                </TableCell>
                <TableCell align="center" className={classes.cellHeader}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {medicalConditions.map(medicalCondition => (
                <TableRow key={nanoid()} className={classes.row}>
                  <TableCell align="center">{medicalCondition.name}</TableCell>
                  <TableCell align="center">{medicalCondition.note}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Eliminar">
                      <IconButton
                        onClick={() => {
                          onDeleteMedicalConditionHandler(medicalCondition);
                        }}
                      >
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onOpenFormHandler}
          >
            Agregar nueva condición
          </Button>
        </Box>
        {isFormOpen && (
          <Box mt={2}>
            <Grid container spacing={4} style={{ marginBottom: '16px' }}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="conditionLabel">
                    Nombre de la condición *
                  </InputLabel>
                  <Select
                    error={nameField.error}
                    value={nameField.value}
                    onChange={nameField.onChange}
                    inputProps={{ id: 'conditionLabel' }}
                    style={{
                      maxHeight: '300px',
                    }}
                  >
                    {MEDICAL_CONDITIONS.map(condition => (
                      <MenuItem key={condition} value={condition}>
                        {condition}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Nota"
                  color="secondary"
                  helperText={
                    noteField.error ? 'Este campo es requerido' : 'Notas'
                  }
                  error={noteField.error}
                  value={noteField.value}
                  onChange={noteField.onChange}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSubmitHandler}
            >
              Agregar
            </Button>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};
