import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Tooltip,
} from '@material-ui/core';
import { nanoid } from '@reduxjs/toolkit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useFormContext } from 'react-hook-form';
import { useField } from 'hooks/useField.hook';
import { generateTheme } from '../../../utils/helpers';
import {
  HISTORICAL_CONDITIONS,
  MEDICAL_CONDITIONS,
} from '../medical-conditions/medical-conditions-table.utils';

const useStyles = makeStyles(theme => ({
  head: {
    backgroundColor: '#293241',
  },
  cellHeader: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    color: 'white',
  },
  row: {
    '&:nth-child(odd)': {
      backgroundColor: '#e3e3e3',
    },
    '&:nth-child(even)': {
      backgroundColor: '#a9a9a9',
    },
  },
}));

export const MedicalHistoricalTable = () => {
  const [isFormOpen, toggleForm] = useState(false);

  const nameField = useField('');

  const affectedField = useField('');

  const noteField = useField('');

  const form = useFormContext();

  const classes = useStyles();

  const medicalHistorical = form.watch('medicalHistorical');

  const onDeleteMedicalHistoricalHandler = item => {
    const _medicalHistorical = medicalHistorical.filter(
      medicalHistorical => medicalHistorical._id !== item._id,
    );
    form.setValue('medicalHistorical', _medicalHistorical);
  };

  const onOpenFormHandler = () => {
    toggleForm(prevState => !prevState);
  };

  const onSubmitHandler = () => {
    const name = nameField.value;
    const affected = affectedField.value;
    const note = noteField.value;

    const nameValidation = nameField.validate();
    const affectedValidation = affectedField.validate();
    const noteValidation = noteField.validate();

    if (nameValidation || noteValidation || affectedValidation) {
      const payload = {
        name,
        affected,
        note,
        _id: nanoid(),
      };
      const _medicalHistorical = [...medicalHistorical, payload];
      form.setValue('medicalHistorical', _medicalHistorical);
      toggleForm(false);
      nameField.reset();
      affectedField.reset();
      noteField.reset();
    }
  };

  return (
    <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
      <Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell align="center" className={classes.cellHeader}>
                  Condición
                </TableCell>
                <TableCell align="center" className={classes.cellHeader}>
                  Paciente/Antecedente familiar
                </TableCell>
                <TableCell align="center" className={classes.cellHeader}>
                  Nota
                </TableCell>
                <TableCell align="center" className={classes.cellHeader}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {medicalHistorical.map(medicalHistorical => (
                <TableRow key={nanoid()} className={classes.row}>
                  <TableCell align="center">{medicalHistorical.name}</TableCell>
                  <TableCell align="center">
                    {
                      {
                        patient: 'Presente en paciente',
                        family: 'Antecedente familiar',
                      }[medicalHistorical.affected]
                    }
                  </TableCell>
                  <TableCell align="center">{medicalHistorical.note}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Eliminar">
                      <IconButton
                        onClick={() => {
                          onDeleteMedicalHistoricalHandler(medicalHistorical);
                        }}
                      >
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onOpenFormHandler}
          >
            Agregar nueva condición
          </Button>
        </Box>
        {isFormOpen && (
          <Box mt={2}>
            <Grid container spacing={4} style={{ marginBottom: '16px' }}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="conditionLabel">
                    Nombre de la condición *
                  </InputLabel>
                  <Select
                    error={nameField.error}
                    value={nameField.value}
                    onChange={nameField.onChange}
                    inputProps={{ id: 'conditionLabel' }}
                    style={{
                      maxHeight: '300px',
                    }}
                  >
                    {HISTORICAL_CONDITIONS.map(condition => (
                      <MenuItem key={condition} value={condition}>
                        {condition}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="affectedLabel">
                    Condición o antecedente familiar *
                  </InputLabel>
                  <Select
                    error={affectedField.error}
                    value={affectedField.value}
                    onChange={affectedField.onChange}
                    inputProps={{ id: 'affectedLabel' }}
                    style={{
                      maxHeight: '300px',
                    }}
                  >
                    {[
                      { label: 'Antecedente familiar', value: 'family' },
                      { label: 'Presente en paciente', value: 'patient' },
                    ].map(condition => (
                      <MenuItem key={condition.value} value={condition.value}>
                        {condition.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Nota"
                  color="secondary"
                  helperText={
                    noteField.error ? 'Este campo es requerido' : 'Notas'
                  }
                  error={noteField.error}
                  value={noteField.value}
                  onChange={noteField.onChange}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSubmitHandler}
            >
              Agregar
            </Button>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};
