import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

import {
  makeStyles,
  Container,
  Typography,
  Grid,
  Box,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core';

import { SettingsNavigation } from 'components';

import { Main } from './sections';

import ActivityPage from './Activity/Activity.page';
import PaymentsPage from './Payments/Payments.page';

import { useCards } from 'hooks/payments.hook';
import {
  useGetProfile,
  useGetPaymentsHistory,
  useGetNutritionalHistory,
} from 'hooks/user.hook';
import { useEffect } from 'react';
import { LifeStylePage } from './LifeStyle/LifeStylePage.component';
import {MedicalProfilePage} from "./medical-profile-page/medical-profile-page.component";

const Settings = () => {
  const {
    data: profile,
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
  } = useGetProfile();

  const {
    data: cards,
    isError: isErrorCards,
    isLoading: isLoadingCards,
  } = useCards();

  const {
    data: paymentsHistory,
    isError: isErrorPaymentsHistory,
    isLoading: isLoadingPaymentsHistory,
  } = useGetPaymentsHistory();

  const {
    data: nutritionalHistory,
    isError: isErrorNutritionalHistory,
    isLoading: isLoadingNutritionalHistory,
  } = useGetNutritionalHistory();

  const classes = useStyles();
  const location = useLocation();

  const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const { path } = useRouteMatch();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, []);

  if (
    isLoadingCards ||
    isLoadingProfile ||
    isLoadingPaymentsHistory ||
    isLoadingNutritionalHistory
  ) {
    return (
      <Box pt={4} display="flex" justifyContent="center">
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (
    isErrorCards ||
    isErrorProfile ||
    isErrorPaymentsHistory ||
    isErrorNutritionalHistory
  ) {
    return (
      <Box pt={4}>
        <Typography>No se pudo cargar la información</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.backdrop}>
      <Helmet>
        <title>Mi Perfil | Wellnub</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={4}>
          {!isResponsive && (
            <Grid item xs={12} sm={2}>
              <SettingsNavigation />
            </Grid>
          )}
          <Grid item xs={12} sm={10}>
            <Switch>
              <Route exact path={path}>
                <Redirect to={`${path}/activity`} />
              </Route>
              <Route path={`${path}/profile`}>
                <Main profile={profile.data} />
              </Route>
              <Route path={`${path}/payments`}>
                <PaymentsPage cards={cards.data} />
              </Route>
              <Route path={`${path}/medical-profile`}>
                <MedicalProfilePage />
              </Route>
              <Route path={`${path}/life-style`}>
                <LifeStylePage />
              </Route>
              <Route path={`${path}/activity`}>
                <ActivityPage
                  nutritionalHistory={nutritionalHistory}
                  paymentsHistory={paymentsHistory}
                />
              </Route>
            </Switch>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  grid: {
    paddingTop: theme.spacing(5),
  },
  backdrop: {
    minHeight: '90vh',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default Settings;
