import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { generateTheme } from 'utils/helpers';
import { wnUserMedicalProfileSelector } from 'redux/slices/wnUser/wnUser.selector';
import { MedicalConditionsTable } from 'components/Tables/medical-conditions/medical-conditions-table.component';
import { MedicalHistoricalTable } from 'components/Tables/medical-historical/medical-historical-table.component';
import { MedicationsTable } from 'components/Tables/medications/medications-table.component';
import { useUpdateProfile } from 'hooks/user.hook';

export const MedicalProfilePage = () => {
  const medicalProfile = useSelector(wnUserMedicalProfileSelector);

  const form = useForm({
    defaultValues: {
      hasMedicalCondition:
        medicalProfile?.patientConditions?.hasPatientConditions,
      medicalConditions:
        medicalProfile?.patientConditions?.patientConditions ?? [],
      hasMedicalHistorical: medicalProfile?.conditions?.hasCondition,
      medicalHistorical: medicalProfile?.conditions?.conditions ?? [],
      isTakingMedication: medicalProfile?.medications?.isTakingMedication,
      medications: medicalProfile?.medications?.medicationList ?? [],
    },
  });

  const updateProfile = useUpdateProfile();

  const onSubmitHandler = form.handleSubmit(data => {
    const {
      isTakingMedication,
      medications,
      hasMedicalCondition,
      hasMedicalHistorical,
      medicalConditions,
      medicalHistorical,
    } = data;
    const payload = {
      medicalProfile: {
        medications: {
          isTakingMedication: isTakingMedication,
          medicationList: isTakingMedication
            ? medications.map(({ _id, ...restMedication }) => restMedication)
            : [],
        },
        patientConditions: {
          hasPatientConditions: hasMedicalCondition,
          patientConditions: hasMedicalCondition
            ? medicalConditions.map(
                ({ _id, ...restCondition }) => restCondition,
              )
            : [],
        },
        conditions: {
          hasCondition: hasMedicalHistorical,
          conditions: hasMedicalHistorical
            ? medicalHistorical.map(
                ({ _id, ...restCondition }) => restCondition,
              )
            : [],
        },
      },
    };
    updateProfile.mutate(payload);
  });

  const [hasMedicalConditions, hasMedicalHistorical, isTakingMedications] =
    form.watch([
      'hasMedicalCondition',
      'hasMedicalHistorical',
      'isTakingMedication',
    ]);

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmitHandler}>
        <Box component="section" mb={4}>
          <Typography
            variant="h6"
            color="primary"
            style={{
              color: 'black',
              textTransform: 'uppercase',
              paddingBottom: 16,
            }}
          >
            Condiciones médicas del paciente y antecedentes familiares
          </Typography>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 16,
            }}
          >
            <Controller
              name="hasMedicalCondition"
              control={form.control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  label="¿El paciente tiene una condición médica actual?"
                  control={
                    <ThemeProvider
                      theme={theme => generateTheme(theme, 'light')}
                    >
                      <Checkbox
                        checked={value}
                        onChange={onChange}
                        color="secondary"
                      />
                    </ThemeProvider>
                  }
                />
              )}
            />
            {hasMedicalConditions && <MedicalConditionsTable />}
            <Controller
              name="hasMedicalHistorical"
              control={form.control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  label="¿El paciente tiene antecedentes médicos familiares?"
                  control={
                    <ThemeProvider
                      theme={theme => generateTheme(theme, 'light')}
                    >
                      <Checkbox
                        checked={value}
                        onChange={onChange}
                        color="secondary"
                      />
                    </ThemeProvider>
                  }
                />
              )}
            />
            {hasMedicalHistorical && <MedicalHistoricalTable />}
          </Box>
        </Box>
        <Box component="section" mb={4}>
          <Typography
            variant="h6"
            color="primary"
            style={{
              color: 'black',
              textTransform: 'uppercase',
              paddingBottom: 16,
            }}
          >
            Medicamentos
          </Typography>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 16,
            }}
          >
            <Controller
              name="isTakingMedication"
              control={form.control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  label="¿El paciente toma algún medicamento?"
                  control={
                    <ThemeProvider
                      theme={theme => generateTheme(theme, 'light')}
                    >
                      <Checkbox
                        checked={value}
                        onChange={onChange}
                        color="secondary"
                      />
                    </ThemeProvider>
                  }
                />
              )}
            />
            {isTakingMedications && <MedicationsTable />}
          </Box>
        </Box>
        <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
          <Button
            variant="contained"
            type="submit"
            disabled={updateProfile.isLoading}
          >
            Actualizar mi perfil
          </Button>
        </ThemeProvider>
      </form>
    </FormProvider>
  );
};
