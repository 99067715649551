import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Tooltip,
} from '@material-ui/core';
import { nanoid } from '@reduxjs/toolkit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useFormContext } from 'react-hook-form';
import { useField } from 'hooks/useField.hook';
import { generateTheme } from '../../../utils/helpers';
import {
  HISTORICAL_CONDITIONS,
  MEDICAL_CONDITIONS,
} from '../medical-conditions/medical-conditions-table.utils';

const useStyles = makeStyles(theme => ({
  head: {
    backgroundColor: '#293241',
  },
  cellHeader: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    color: 'white',
  },
  row: {
    '&:nth-child(odd)': {
      backgroundColor: '#e3e3e3',
    },
    '&:nth-child(even)': {
      backgroundColor: '#a9a9a9',
    },
  },
}));

export const MedicationsTable = () => {
  const [isFormOpen, toggleForm] = useState(false);

  const nameField = useField('');

  const doseField = useField('');

  const timeUsingField = useField('');

  const noteField = useField('');

  const form = useFormContext();

  const classes = useStyles();

  const medications = form.watch('medications');

  const onDeleteMedicationsHandler = item => {
    const _medications = medications.filter(
      medication => medication._id !== item._id,
    );
    form.setValue('medications', _medications);
  };

  const onOpenFormHandler = () => {
    toggleForm(prevState => !prevState);
  };

  const onSubmitHandler = () => {
    const name = nameField.value;
    const dose = doseField.value;
    const timeUsing = timeUsingField.value;
    const note = noteField.value;

    const nameValidation = nameField.validate();
    const doseValidation = doseField.validate();
    const timeUsingValidation = timeUsingField.validate();
    const noteValidation = noteField.validate();

    if (
      nameValidation ||
      noteValidation ||
      doseValidation ||
      timeUsingValidation
    ) {
      const payload = {
        name,
        dose,
        timeUsing,
        note,
        _id: nanoid(),
      };
      const _medications = [...medications, payload];
      form.setValue('medications', _medications);
      toggleForm(false);
      nameField.reset();
      doseField.reset();
      timeUsingField.reset();
      noteField.reset();
    }
  };

  return (
    <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
      <Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell align="center" className={classes.cellHeader}>
                  Nombre
                </TableCell>
                <TableCell align="center" className={classes.cellHeader}>
                  Dosis
                </TableCell>
                <TableCell align="center" className={classes.cellHeader}>
                  Tiempo con el medicamento
                </TableCell>
                <TableCell align="center" className={classes.cellHeader}>
                  Nota
                </TableCell>
                <TableCell align="center" className={classes.cellHeader}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {medications.map(medication => (
                <TableRow key={nanoid()} className={classes.row}>
                  <TableCell align="center">{medication.name}</TableCell>
                  <TableCell align="center">{medication.dose}</TableCell>
                  <TableCell align="center">{medication.timeUsing}</TableCell>
                  <TableCell align="center">{medication.note}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Eliminar">
                      <IconButton
                        onClick={() => {
                          onDeleteMedicationsHandler(medication);
                        }}
                      >
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onOpenFormHandler}
          >
            Agregar nuevo medicamento
          </Button>
        </Box>
        {isFormOpen && (
          <Box mt={2}>
            <Grid container spacing={4} style={{ marginBottom: '16px' }}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Medicamento"
                  color="secondary"
                  helperText={
                    nameField.error
                      ? 'Este campo es requerido'
                      : 'Nombre del medicamento'
                  }
                  error={nameField.error}
                  value={nameField.value}
                  onChange={nameField.onChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Dosis"
                  color="secondary"
                  helperText={
                    doseField.error ? 'Este campo es requerido' : 'Dosis'
                  }
                  error={doseField.error}
                  value={doseField.value}
                  onChange={doseField.onChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Tiempo con el medicamento"
                  color="secondary"
                  helperText={
                    timeUsingField.error
                      ? 'Este campo es requerido'
                      : 'Tiempo con el medicamento'
                  }
                  error={timeUsingField.error}
                  value={timeUsingField.value}
                  onChange={timeUsingField.onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nota"
                  color="secondary"
                  helperText={
                    noteField.error ? 'Este campo es requerido' : 'Notas'
                  }
                  error={noteField.error}
                  value={noteField.value}
                  onChange={noteField.onChange}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSubmitHandler}
            >
              Agregar
            </Button>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};
