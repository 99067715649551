import { useLocation, Link, useRouteMatch } from 'react-router-dom';

import { Tab, Tabs, useMediaQuery } from '@material-ui/core';

import { useStyles } from './SettingsNavigation.styles';

const SettingsNavigation = () => {
  const { pathname } = useLocation();
  const { url } = useRouteMatch();

  const responsive = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const classes = useStyles();

  const tabValueHandler = () => {
    if (pathname.includes('medical-profile')) return 'medical-profile';
    if (pathname.includes('profile')) return 'profile';
    if (pathname.includes('payments')) return 'payments';
    if (pathname.includes('activity')) return 'activity';
    if (pathname.includes('life-style')) return 'life-style';
    return 'profile';
  };

  return (
    <Tabs
      variant="scrollable"
      className={classes.tabs}
      value={tabValueHandler()}
      scrollButtons={responsive ? 'on' : 'off'}
      orientation={responsive ? 'horizontal' : 'vertical'}
      indicatorColor="primary"
    >
      <Tab
        value="activity"
        component={Link}
        label="Mi actividad"
        to={`${url}/activity`}
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        value="profile"
        component={Link}
        label="Información general"
        to={`${url}/profile`}
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        value="life-style"
        component={Link}
        label="Estilo de vida"
        to={`${url}/life-style`}
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        value="medical-profile"
        component={Link}
        label="Perfil médico"
        to={`${url}/medical-profile`}
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        value="payments"
        component={Link}
        label="Formas de pago"
        to={`${url}/payments`}
        classes={{
          wrapper: classes.tab,
        }}
      />
    </Tabs>
  );
};

export default SettingsNavigation;
