export const MEDICAL_CONDITIONS = [
  'Prediabetes',
  'Diabetes',
  'Obesidad',
  'Hipertensión',
  'Problemas digestivos',
  'Colesterol alto',
  'Triglicéridos altos',
  'Enfermedad celiaca',
  'Cáncer',
  'Alergias',
  'SOP',
  'Desbalances hormonales',
  'Tiroides',
  'Enfermedades respiratorias clínicas',
  'Artritis / reumas',
  'Anemia',
  'Enfermedad cardiovascular',
  'Enfermedad renal',
  'Trastornos alimenticios',
  'Problemas de piel',
  'Hepática',
  'Depresión',
  'Ansiedad',
  'Enfermedades autoinmunes',
  'Embarazo',
].toSorted((a, b) => a.localeCompare(b));

export const HISTORICAL_CONDITIONS = [
  'Diabetes',
  'Hipertensión',
  'Cáncer',
  'Colestrol alto',
].toSorted((a, b) => a.localeCompare(b));
